*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f3f3f3;
}
#main_root{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/* Logo and Some text */
.index-container{
  width:80%;
  padding: 10px;
  height:auto;
  background: #ffffff;
  box-shadow: 1px 1px 40px rgba(0, 0, 0, 0.103);
  border-radius: 20px;
}
.process{
width: 100%;
height: 500px;
display: flex;
flex-direction: column;
align-items: center;
justify-content:flex-start;
padding: 0px;
gap: 30px;
}
.process .logo{
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.process .logo img{
  width:260px;
}
.process .Content{
  height: 90px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.process .Content h1{
  text-align: center;
  font-weight: bold;
  font-size: 50px;
  text-transform: uppercase;
}
.process .pharagraph{
  height: 60px;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.process .pharagraph p{
  font-size: 20px;
  color: rgb(49, 49, 49);
}
.footer{
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer .lines{
  transform: translateY(-5px);
  font-size: 30px;
  margin: 0px 5px;
}
.footer .social{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.footer .social .links{
  width: 30px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer .social img{
  width: 30px;
  cursor: pointer;
}
.footer .mobile{
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
}
.footer .mobile img{
  width: 25px;
  margin: 0px 6px;
}
.footer .email{
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
}
.footer .email img{
  width: 30px;
  margin: 0px 8px;
}
/* Socil Media */
 /* Extra small devices (portrait phones, less than 576px) */
 @media (max-width: 575.98px) {
  .footer{
    margin: 10px 0px;
    width: 100%;
    height:auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .footer .lines{
    display: none;
  }
  .footer .social{
    gap: 3px;
  }
  .footer .social .links{
    width: 31px;
  }
  
  .footer .social img{
    width: 20px;
    cursor: pointer;
  }
  .footer .mobile{
    margin: 10px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
  }
  .footer .mobile img{
    width: 18px;
    margin: 0px 6px;
  }
  .footer .email{
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
  }
  .footer .email img{
    width: 20px;
    margin: 0px 8px;
  }
/* Logo and Some text */
.index-container{
  width:90%;
  padding: 10px;
}
.process{
width: 100%;
height:auto;
padding: 5px;
gap: 5px;
}
.process .logo{
  width: 100%;
  height: auto;
  margin: 10px 0px;
}
.process .logo img{
  width:120px;
}
.process .Content{
  height:auto;
  width: 100%;
}
.process .Content h1{
  text-align: center;
  font-weight: bold;
  font-size:1.2em;
  text-transform: uppercase;
}
.process .pharagraph{
  height:auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.process .pharagraph p{
  font-size: 1em;
  color: rgb(49, 49, 49);
}

/* Socil Media */

}


/* For small tablets and larger mobile devices */
@media (min-width: 576px) {
  .footer{
    margin: 10px 0px;
    width: 100%;
    height:auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .footer .lines{
    display: none;
  }
  .footer .social{
    gap: 10px;
  }
  .footer .social .links{
    width: 31px;
  }
  
  .footer .social img{
    width: 28px;
    cursor: pointer;
  }
  .footer .mobile{
    margin: 10px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
  }
  .footer .mobile img{
    width: 18px;
    margin: 0px 6px;
  }
  .footer .email{
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
  }
  .footer .email img{
    width: 20px;
    margin: 0px 8px;
  }
/* Logo and Some text */
.index-container{
  width:90%;
  padding: 10px;
}
.process{
width: 100%;
height:auto;
padding: 5px;
gap: 5px;
}
.process .logo{
  width: 100%;
  height: auto;
  margin: 10px 0px;
}
.process .logo img{
  width:120px;
}
.process .Content{
  height:auto;
  width: 100%;
}
.process .Content h1{
  text-align: center;
  font-weight: bold;
  font-size:1.7em;
  text-transform: uppercase;
}
.process .pharagraph{
  height:auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.process .pharagraph p{
  font-size: 1.1em;
  color: rgb(49, 49, 49);
}

/* Socil Media */

}

/* For tablets and larger screens */
@media (min-width: 768px) {

  .footer{
    margin: 10px 0px;
    width: 100%;
    height:auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .footer .lines{
    display:flex;
  }
  .footer .social{
    gap: 5px;
  }
  .footer .social .links{
    width: 31px;
  }
  
  .footer .social img{
    width: 30px;
    cursor: pointer;
  }
  .footer .mobile{
    margin: 10px 10px;
    width: 190px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    font-weight: bold;
  }
  .footer .mobile img{
    width: 24px;
    margin: 0px 6px;
  }
  .footer .email{
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    font-weight: bold;
  }
  .footer .email img{
    width: 24px;
    margin: 0px 8px;
  }
/* Logo and Some text */
.index-container{
  width:90%;
}
.process{
width: 100%;
height:auto;
padding: 5px;
gap: 5px;
margin: 40px 0px;
}
.process .logo{
  width: 100%;
  height: auto;
  margin: 10px 0px;
}
.process .logo img{
  width:190px;
}
.process .Content{
  height:auto;
  width: 100%;
}
.process .Content h1{
  text-align: center;
  font-weight: bold;
  font-size:2em;
  text-transform: uppercase;
}
.process .pharagraph{
  height:auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.process .pharagraph p{
  font-size: 1.2em;
  color: rgb(49, 49, 49);
}

/* Socil Media */

}

/* For small laptops and larger tablets */
@media (min-width: 992px) {
  
/* Logo and Some text */
.index-container{
  width:90%;
}
.process{
width: 100%;
height:auto;
padding: 5px;
gap: 5px;
margin: 40px 0px;
}
.process .logo{
  width: 100%;
  height: auto;
  margin: 10px 0px;
}
.process .logo img{
  width:190px;
}
.process .Content{
  height:auto;
  width: 100%;
}
.process .Content h1{
  text-align: center;
  font-weight: bold;
  font-size:3em;
  text-transform: uppercase;
}
.process .pharagraph{
  height:auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.process .pharagraph p{
  font-size: 1.4em;
  color: rgb(49, 49, 49);
}

/* Socil Media */

}

/* For desktops and larger screens */
@media (min-width: 1200px) {
/* Logo and Some text */
.index-container{
  width:90%;
}
.process{
width: 100%;
height:auto;
padding: 5px;
gap: 5px;
margin: 40px 0px;
}
.process .logo{
  width: 100%;
  height: auto;
  margin: 10px 0px;
}
.process .logo img{
  width:240px;
}
.process .Content{
  height:auto;
  width: 100%;
}
.process .Content h1{
  text-align: center;
  font-weight: bold;
  font-size:3em;
  text-transform: uppercase;
}
.process .pharagraph{
  height:auto;
  width: 790px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.process .pharagraph p{
  font-size: 1.6em;
  color: rgb(49, 49, 49);
}

/* Socil Media */

}

/* For extra-large desktops */
@media (min-width: 1400px) {
  .responsive-text {
    font-size: 2rem; /* 32px */
  }
}